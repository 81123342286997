











































































































































































































































































































































































































































































































































































































































































































































// .courseType1 {
//     .el-switch.is-checked .el-switch__core {
//         background-color: #c0ccda !important;
//         border-color: #c0ccda !important;
//     }
// }
    .summary-title{
        display: flex;
        padding-left:12px;
        .summary-title-item{
            margin-right: 20px;
        }
    }
